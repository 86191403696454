export const namespaced = true;

export const state = {
  isInteractionSidebarVisible: false,
  interactionUnreadCount: 0,
};

export const getters = {
  isInteractionSidebarVisible: (state) => state.isInteractionSidebarVisible,
  interactionUnreadCount: (state) => state.interactionUnreadCount,
};

export const mutations = {
  setInteractionSidebarState(state, isVisible) {
    state.isInteractionSidebarVisible = isVisible;
  },
  setInteractionUnreadCount(state, count) {
    state.interactionUnreadCount = count;
  },
};

export const actions = {
  openInteractionSidebar({ commit }) {
    commit('setInteractionSidebarState', true);
  },
  closeInteractionSidebar({ commit }) {
    commit('setInteractionSidebarState', false);
  },
  setInteractionSidebarState({ commit }, isVisible) {
    commit('setInteractionSidebarState', isVisible);
  },
  clearInteractionUnreadCount({ commit }) {
    commit('setInteractionUnreadCount', 0);
  },

  async getClients({ dispatch }, payload) {
    const { result, data, status } = await dispatch('api/get', {
      path: 'clients',
      payload,
    }, { root: true });

    const {
      data: list,
      current_page: page,
      per_page: perPage,
      total,
    } = data;

    if (result) {
      return {
        result,
        status,
        data: {
          list,
          pagination: {
            page,
            perPage,
            total,
          },
        },
      };
    }
    return { result, data, status };
  },
  async getClient({ dispatch }, clientId) {
    return dispatch('api/get', {
      path: `clients/${clientId}`,
    }, { root: true });
  },
  createClient({ dispatch }, payload) {
    return dispatch('api/post', {
      path: 'clients',
      payload,
    }, { root: true });
  },
  deleteClient({ dispatch }, { clientId }) {
    return dispatch('api/delete', {
      path: `clients/${clientId}`,
    }, { root: true });
  },

  async updateClient({ dispatch }, { clientId, model }) {
    return dispatch('api/patch', {
      path: `clients/${clientId}`,
      payload: model,
    }, { root: true });
  },
  updateClientAvatar({ dispatch }, { itemId: clientId, formData }) {
    return dispatch('api/post', {
      path: `clients/${clientId}/avatar`,
      payload: formData,
    }, { root: true });
  },
  deleteClientAvatar({ dispatch }, { itemId: clientId }) {
    return dispatch('api/delete', {
      path: `clients/${clientId}/avatar`,
    }, { root: true });
  },

  async getBankDetails({ dispatch }, clientId) {
    return dispatch('api/get', {
      path: `clients/${clientId}/bank-details`,
    }, { root: true });
  },
  async updateClientBankDetails({ dispatch }, { id, model }) {
    return dispatch('api/patch', {
      path: `clients/${id}/bank-details`,
      payload: model,
    }, { root: true });
  },

  async getClientInteractions({ dispatch, commit }, clientId) {
    const { result, data, status } = await dispatch('api/get', {
      path: `clients/${clientId}/feed`,
    }, { root: true });

    if (result) {
      commit('setInteractionUnreadCount', 0);
    }
    return { result, data, status };
  },
  async getClientInteractionUnreadCount({ dispatch, commit }, clientId) {
    const { result, data, status } = await dispatch('api/get', {
      path: `clients/${clientId}/interactionsUnread`,
    }, { root: true });

    if (result) {
      commit('setInteractionUnreadCount', data);
    }
    return { result, data, status };
  },
  getClientsForSelector({ dispatch }) {
    return dispatch('api/get', {
      path: 'clients/clientsForSelector',
    }, { root: true });
  },
  async addFiles({ dispatch }, { id, files }) {
    const { result, data, status } = await dispatch(
        'api/post', {
            path: `clients/${id}/files`,
            payload: {
              files,
            }
        }, { root: true }
    );

    return { result, data, status };
  },
  async getFiles({ dispatch }, { id, payload }) {
    const { result, data, status } = await dispatch(
        'api/get', {
          path: `clients/${id}/files`,
          payload
        }, { root: true }
    );

    return { result, data, status }
  },
  async deleteFile({ dispatch }, { clientId, fileId, payload }) {
    const { result, data, status } = await dispatch(
        'api/delete', {
          path: `clients/${clientId}/files/${fileId}`,
          payload
        }, { root: true },
    );

    return { result, data, status };
  },
  async getFacilityFiles({ dispatch }, { id, payload }) {
    const { result, data, status } = await dispatch(
        'api/get', {
          path: `clients/${id}/facility-files`,
          payload
        }, { root: true },
    );

    return { result, data, status };
  }
};
