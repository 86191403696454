export function getState() {
  return JSON.parse(localStorage.getItem('vuex_cache') || '{}');
}

export function saveState(field, value) {
  const state = getState();
  const model = {};
  model[field] = value;

  const newState = { ...state, ...model };
  localStorage.setItem('vuex_cache', JSON.stringify(newState));
}
