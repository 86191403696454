export const namespaced = true;

export const state = {
  gridDisplayed: false,
  types: [],
  statuses: [],
};

export const getters = {

};

export const mutations = {

};

export const actions = {
  find({ dispatch }, payload) {
    return dispatch(
      'api/get', {
        path: 'requests',
        payload,
      }, { root: true },
    );
  },
  findOne({ dispatch }, id) {
    return dispatch(
      'api/get', {
        path: `requests/${id}`,
      }, { root: true },
    );
  },
  types({ dispatch }) {
    return dispatch(
      'api/get', {
        path: 'requests/types',
      }, { root: true },
    );
  },
  statuses({ dispatch }) {
    return dispatch(
      'api/get', {
        path: 'requests/statuses',
      }, { root: true },
    );
  },
  workTypes({ dispatch }) {
    return dispatch(
      'api/get', {
        path: 'requests/workTypes',
      }, { root: true },
    );
  },
  update({ dispatch }, { id, model }) {
    return dispatch(
      'api/patch', {
        path: `requests/${id}`,
        payload: model,
      }, { root: true },
    );
  },
  create({ dispatch }, payload) {
    return dispatch(
      'api/post', {
        path: 'requests',
        payload,
      }, { root: true },
    );
  },
  delete({ dispatch }, { id }) {
    return dispatch(
      'api/delete', {
        path: `requests/${id}`,
      }, { root: true },
    );
  },
  setAnswer({ dispatch }, { id, checklistItemId, answer }) {
    return dispatch(
      'api/post', {
        path: `requests/${id}/set-checklist-item-answer`,
        payload: {
          answer,
          checklist_item_id: checklistItemId,
        },
      }, { root: true },
    );
  },
  setAnswerFormData({ dispatch }, { id, formData }) {
    return dispatch(
      'api/post', {
        path: `requests/${id}/set-checklist-item-answer`,
        payload: formData,
      }, { root: true },
    );
  },
    uploadFile({ dispatch }, {formData}) {
      return dispatch(
          'api/post', {
              path: 'tools/upload-files',
              payload: formData,
          }, { root: true },
      );
    },
    async uploadFiles({ dispatch }, formData) {
      const {result, data, files, status} = await dispatch(
          'api/post', {
              path: 'tools/upload-files',
              payload: formData,
          }, { root: true },
      );

      return { result, data, files, status };
    },
  closeChecklist({ dispatch }, { checklistId, requestId }) {
    return dispatch(
      'api/patch', {
        path: `requests/${requestId}/finish-checklist`,
        payload: {
          checklist_id: checklistId,
        },
      }, { root: true },
    );
  },
  sign({ dispatch }, { requestId, sign }) {
    return dispatch(
      'api/patch', {
        path: `requests/${requestId}/sign`,
        payload: {
          client_sign: sign,
        },
      }, { root: true },
    );
  },
    finish({ dispatch }, { requestId }) {
        return dispatch(
            'api/patch', {
                path: `requests/${requestId}/finish`,
            }, { root: true },
        );
    },
    changePrice({ dispatch }, { price, comment, id }) {
      return dispatch(
          'api/patch', {
              path: `requests/${id}/change-price`,
              payload: {
                  price, comment
              },
          }, { root: true },
      )
    },
    // eslint-disable-next-line camelcase
    reschedule({ dispatch }, { id, comment, initiator, date }) {
      return dispatch(
          'api/patch', {
              path: `requests/${id}/change-deadline`,
              payload: {
                  comment,
                  initiator,
                  deadline_at: date.value
              },
          }, { root: true },
      )
    },
    cancel({ dispatch }, { id, comment, initiator }) {
      return dispatch(
          'api/patch', {
              path: `requests/${id}/cancel`,
              payload: {
                  comment,
                  initiator,
              }
          }, { root: true },
      )
    },
    async addFiles({ dispatch }, { id, files }) {
      const { result, data, status } = await dispatch(
          'api/post', {
              path: `requests/${id}/files`,
              payload: {
                  files,
              }
          }, { root: true },
      );

      return { result, data, status };
    },
    async getFiles({ dispatch }, { id, payload }) {
      const { result, data, status } = await dispatch(
          'api/get', {
              path: `requests/${id}/files`,
              payload
          }, { root: true },
      );

      return { result, data, status };
    },
    async deleteFile({ dispatch }, { requestId, fileId, payload }) {
      const { result, data, status } = await dispatch(
          'api/delete', {
              path: `requests/${requestId}/files/${fileId}`,
              payload
          }, { root: true },
      );

      return { result, data, status };
    },
    async getFacilityFiles({ dispatch }, { id, payload }) {
        const { result, data, status } = await dispatch(
            'api/get', {
                path: `requests/${id}/facility-files`,
                payload
            }, { root: true },
        );

        return { result, data, status };
    }
};
