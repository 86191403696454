import axios from 'axios';
import { throttleAdapterEnhancer } from 'axios-extensions';

// import { requestOnResolve, requestOnReject } from '@/store/api/interceptors';
import Cookies from "js-cookie";
import {useStore} from "@/hooks/useStore";

const throttleConfig = {
  threshold: 1000,
};

export const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  adapter: throttleAdapterEnhancer(axios.defaults.adapter, throttleConfig),
});

axiosIns.interceptors.request.use((config) => {
  const token = Cookies.get('access-token');

  if (!token) return config;
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
  };
})

function createResponseInterceptor() {
  const resInterceptor = axiosIns.interceptors.response.use(
      async (response) => response,
      async (error) => {
        const accessToken = Cookies.get('access-token');
        const refreshToken = Cookies.get('refresh-token');

        if (error.response.status === 401 && accessToken && refreshToken) {
          axiosIns.interceptors.response.eject(resInterceptor);

          const store = useStore();

          return axiosIns.post('/auth/refresh', { refresh_token: refreshToken }).then((res) => {
            if (res.status === 200) {
              const remember = Cookies.get('remember');
              if (remember) Cookies.set('remember', remember, { expires: 32 });

              Cookies.set('access-token', res.data.response.access_token);
              Cookies.set('refresh-token', res.data.response.refresh_token);

              // eslint-disable-next-line no-param-reassign
              error.response.config.headers.Authorization = `Bearer ${res.data.response.access_token}`;

              return axiosIns(error.response.config);
            }
            Cookies.remove('access-token');
            Cookies.remove('refresh-token');
            Cookies.remove('remember');

            store.dispatch('user/clearUserData');

            return null;
          }).finally(createResponseInterceptor);
        }

        return Promise.reject(error);
      }
  )
}

createResponseInterceptor();

export const namespaced = true;

export const actions = {
  async request(context, { method, path, payload }) {
    try {
      const res = await axiosIns[method](path, payload);
      return {
        result: true,
        data: res.data.response || null,
        status: res.status,
      };
    } catch (err) {
      return {
        result: false,
        data: err.response?.data || null,
        status: err.response?.status,
      };
    }
  },
  get: async ({ dispatch }, { path, payload }) => dispatch('request', {
    method: 'get',
    path,
    payload: { params: payload },
  }),
  post: async ({ dispatch }, { path, payload }) => dispatch('request', {
    method: 'post',
    path,
    payload,
  }),
  put: async ({ dispatch }, { path, payload }) => dispatch('request', {
    method: 'put',
    path,
    payload,
  }),
  patch: async ({ dispatch }, { path, payload }) => dispatch('request', {
    method: 'patch',
    path,
    payload,
  }),
  delete: async ({ dispatch }, { path, payload }) => dispatch('request', {
    method: 'delete',
    path,
    payload: {
      data: payload,
    },
  }),
};
