export const namespaced = true;

export const actions = {
  getList({ dispatch }, { type }) {
    return dispatch('api/get', {
      path: 'tags',
      payload: {
        item_type: type,
      },
    }, { root: true });
  },
  async addTag({ dispatch }, { id, type, tag }) {
    return dispatch('api/post', {
      path: `tags/${type}/${id}`,
      payload: {
        tags: [tag],
      },
    }, { root: true });
  },
  async removeTag({ dispatch }, { id, type, tagId }) {
    return dispatch('api/delete', {
      path: `tags/${type}/${id}/${tagId}`,
    }, { root: true });
  },
};
