import {axiosIns} from '@/store/api';

export const namespaced = true;

export const state = {
  isInteractionSidebarVisible: false,
  interactionUnreadCount: 0,

  facilityTypes: null,
  showServicemans: true,
};

export const getters = {
  isInteractionSidebarVisible: (state) => state.isInteractionSidebarVisible,
  interactionUnreadCount: (state) => state.interactionUnreadCount,
  showServicemans: (state) => state.showServicemans,
};

export const mutations = {
  setInteractionSidebarState(state, isVisible) {
    state.isInteractionSidebarVisible = isVisible;
  },
  setInteractionUnreadCount(state, count) {
    state.interactionUnreadCount = count;
  },
  setFacilityTypes(state, types) {
    state.facilityTypes = types;
  },
  SET_SHOW_SERVICEMANS(state, value) {
    state.showServicemans = value.value;
  },
};

export const actions = {
  openInteractionSidebar({ commit }) {
    commit('setInteractionSidebarState', true);
  },
  closeInteractionSidebar({ commit }) {
    commit('setInteractionSidebarState', false);
  },
  setInteractionSidebarState({ commit }, isVisible) {
    commit('setInteractionSidebarState', isVisible);
  },
  clearInteractionUnreadCount({ commit }) {
    commit('setInteractionUnreadCount', 0);
  },
  setShowServicemans({ commit }, newValue) {
    commit('SET_SHOW_SERVICEMANS', newValue);
  },

  async getFacilityTypes({ dispatch }) {
    return dispatch(
      'api/get', {
        path: 'facilities/types',
      }, { root: true },
    );
  },

  async getFacilitiesForSelector({ dispatch }, payload) {
    return dispatch('api/get', {
      path: 'facilities/facilitiesForSelector',
      payload,
    }, { root: true });
  },
  async getFacilities({ dispatch }, payload) {
    const { result, data, status } = await dispatch(
      'api/get', {
        path: 'facilities',
        payload,
      }, { root: true },
    );

    const {
      data: list,
      current_page: page,
      per_page: perPage,
      total,
    } = data;

    if (result) {
      return {
        result,
        status,
        data: {
          list,
          pagination: {
            page,
            perPage,
            total,
          },
        },
      };
    }
    return { result, data, status };
  },
  async getFacilitiesMap({ dispatch }, payload) {
    const { result, data, status } = await dispatch(
        'api/get', {
          path: 'facilities/map-data',
          payload,
        }, { root: true },
    );

    if (result) {
      return {
        result,
        status,
        data,
      };
    }
    return { result, data, status };
  },
  async getFacility({ dispatch }, facilityId) {
    return dispatch(
      'api/get', {
        path: `facilities/${facilityId}`,
      }, { root: true },
    );
  },
  createFacility({ dispatch }, payload) {
    return dispatch(
      'api/post', {
        path: 'facilities',
        payload,
      }, { root: true },
    );
  },
  deleteFacility({ dispatch }, { facilityId }) {
    return dispatch(
      'api/delete', {
        path: `facilities/${facilityId}`,
      }, { root: true },
    );
  },

  async updateFacility({ dispatch }, { facilityId, model }) {
    return dispatch(
      'api/patch', {
        path: `facilities/${facilityId}`,
        payload: model,
      }, { root: true },
    );
  },
  updateFacilityAvatar({ dispatch }, { itemId: facilityId, formData }) {
    return dispatch(
      'api/post', {
        path: `facilities/${facilityId}/avatar`,
        payload: formData,
      }, { root: true },
    );
  },
  deleteFacilityAvatar({ dispatch }, { itemId: facilityId }) {
    return dispatch(
      'api/delete', {
        path: `facilities/${facilityId}/avatar`,
      }, { root: true },
    );
  },

  async getFacilityInteraction({ dispatch, commit }, facilityId) {
    const { result, data, status } = await dispatch(
      'api/get', {
        path: `facilities/${facilityId}/interaction`,
      }, { root: true },
    );

    if (result) {
      commit('setInteractionUnreadCount', 0);
    }
    return { result, data, status };
  },
  async getFacilityInteractionUnreadCount({ dispatch, commit }, facilityId) {
    const { result, data, status } = await dispatch(
      'api/get', {
        path: `facilities/${facilityId}/interactionsUnread`,
      }, { root: true },
    );

    if (result) {
      commit('setInteractionUnreadCount', data);
    }
    return { result, data, status };
  },

  getRequestsByFacilityId({ dispatch }, { id: facilityId }) {
    return dispatch(
      'api/get', {
        path: `facilities/${facilityId}/requests`,
      }, { root: true },
    );
  },

  syncEquipments({ dispatch }, { id, equipmentIds }) {
    return dispatch(
      'api/patch', {
        path: `facilities/${id}/sync-equipment`,
        payload: {
          equipment_ids: equipmentIds,
        },
      }, { root: true },
    );
  },

  async getServiceLog({ dispatch }, { id, dateTo, dateFrom }) {
    return dispatch(
      'api/get', {
        path: `facilities/${id}/service-log`,
        payload: {
          dateTo,
          dateFrom,
        },
      }, { root: true },
    );
  },
  // eslint-disable-next-line no-unused-vars
  async getExportData({ dispatch }, { id, dateTo, dateFrom }) {
    const data = await axiosIns.get(`facilities/${id}/service-log-export`, {
      responseType: 'blob',
      params: {
        dateTo,
        dateFrom,
      }})
    return data;
  },

  async addFiles({ dispatch }, { id, files }) {
    const { result, data, status } = await dispatch(
        'api/post', {
          path: `facilities/${id}/files`,
          payload: {
            files,
          }
        }, { root: true }
    );

    return { result, data, status }
  },

  async getFiles({ dispatch }, { id, payload }) {
    const { result, data, status } = await dispatch(
        'api/get', {
          path: `facilities/${id}/files`,
          payload
        }, { root: true }
    );

    return { result, data, status }
  },

  async deleteFile({ dispatch }, { facilityId, fileId, payload }) {
    const { result, data, status } = await dispatch(
        'api/delete', {
          path: `facilities/${facilityId}/files/${fileId}`,
          payload
        }, { root: true },
    );

    return { result, data, status };
  },

  async getRequestFiles({ dispatch }, { id, payload }) {
    const { result, data, status } = await dispatch(
        'api/get', {
          path: `facilities/${id}/request-files`,
          payload
        }, { root: true }
    );

    return { result, data, status }
  }
};
