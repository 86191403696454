import {axiosIns} from "@/store/api";

export const namespaced = true;

export const state = {
    list: {},
};

export const getters = {
    list: (state) => state.list,
};

export const mutations = {
    SET_DICTIONARIES(state, data) {
        state.list = {...data};
    }
};

export const actions = {
    async getDictionaries({ commit }, { dictionaries }) {
        let result = false;
        await axiosIns.get(`dictionaries?by_name=${dictionaries}`)
            .then((res) => {
                if (res.status === 200) {
                    result = true;
                    commit('SET_DICTIONARIES', res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        return result;
    },

    async getFileTypes({ dispatch }) {
        const data = await dispatch('api/get', {
            path: '/dictionaries?by_name=file_types'
        }, { root: true });

        return data;
    }
};
