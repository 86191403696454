<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
      <div
v-if="showBlockMobileApp"
class="mobile-app w-100"
>
        <div class="d-flex flex-row left-side align-items-center justify-items-center">
            <feather-icon
                    icon="XIcon"
                    size="30"
                    @click="setShow(false)"
            />
            <div class="app-block d-flex flex-row">
                <img
                        src="@/assets/images/logo/logo-small.svg"
                        alt="logo"
                >
                <div class="description d-flex flex-column ">
                    <span class="title-description">Servi</span>
                    <span class="description-description">Монтаж и сервисное обслуживание</span>
                </div>
            </div>
        </div>
              <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="md"
                      pill
                      class="btn-icon rounded"
                      @click="openLinkMobileApp"
              >
Загрузить
</b-button>
</div>
    <component :is="layout">
      <router-view />
    </component>
</div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { provideToast } from 'vue-toastification/composition';
import { watch, ref, computed, provide } from '@vue/composition-api';
import { useWindowSize, useCssVar } from '@vueuse/core';
// import { useRouter } from '@core/utils/utils';

import useAppConfig from '@core/app-config/useAppConfig';
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
import {BButton} from 'bootstrap-vue';
import firebaseMessaging from '@/libs/firebase';

import { useStore } from '@/hooks/useStore';
import { useToast } from '@/hooks/useToast';
import packageJson from '@/../package.json';
import {getUserAgent} from '@/utils/getUserAgent';
import {mobileAppState, setShow} from '@/store/localSotrage/MobileAppShow';

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue');

const LayoutFull = () => import('@/layouts/full/LayoutFull.vue');

export default {
  components: {
    BButton,
    // Layouts
    LayoutVertical,
    LayoutFull,
  },
  metaInfo() {
    const { name } = this.$route;
    return {
      title: process.env.VUE_APP_PROJECT_NAME || packageJson.name,
      ...(name ? {
        titleTemplate: `%s — ${this.$t(`pageTitle.${name}`)}`,
      } : {}),
    };
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full';
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2));
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  },
  setup() {
    const store = useStore();
    store.dispatch('cache/restoreCacheState');

    const { skin, skinClasses } = useAppConfig();

    provide('firebase', firebaseMessaging);

    if (skin.value === 'dark') document.body.classList.add('dark-layout');

    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    });

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
    // const windowWidth = computed(() => useWindowSize()?.width?.value);
    const windowWidth = ref(useWindowSize()?.width);
    // const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val);
    });

    const appLoading = document.getElementById('loading-bg');
    if (appLoading) {
      appLoading.style.display = 'none';
    }
    const navigator = getUserAgent();
    const mobileShow = ref(navigator !== 'desktop');
    const mobileLink = navigator === 'iphone' ? 'https://apps.apple.com/ru/app/servi/id6444701453' : 'https://play.google.com/store/apps/details?id=ru.servifsm.appservi';
    const openLinkMobileApp = () => {
            window.location.href = mobileLink;
    }
    const showBlockMobileApp = computed(() => {
      if (mobileShow.value) {
            return mobileAppState.value.show;
      }
      return false;
    });
    const { successToast } = useToast();
    const emailVerified = computed(() => store.state.emailVerified);
    watch(emailVerified, (payload) => {
      if (payload) {
        successToast('Почта успешно подтверждена');
        store.commit('confirmEmail', false);
      }
    });

    return {
      skinClasses,
      showBlockMobileApp,
      mobileLink,
      openLinkMobileApp,
      setShow
    };
  },
};
</script>
<style lang="scss">
.left-side{
    gap:7px;
}
.sr-only {
  display: none;
  position: fixed;
}
.mobile-app{
    padding: 15px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap:7px;
    position: fixed;
    z-index: 999;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.app-block{
gap:10px;
    img{
        object-fit: scale-down;
        max-width: 60px;
        max-height: 60px;
        width: 100%;
    }
    justify-content: center;
}
.description{
    gap:3px;
    justify-content: center;
    .title-description{
        font-weight: bold;
        font-size: 17px;
    }
    .description-description{
        font-size: 11px;
    }
}

</style>
