import { axiosIns } from '../api/index';

/**
 * Method to get all employees
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAllEmployees = () => axiosIns.get('account/employeesForSelector');

/**
 * Method to get all managers
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAllManagers = () => axiosIns.get('account/managersForSelector');

export {
  getAllEmployees,
  getAllManagers,
};
