import { $themeBreakpoints } from '@themeConfig';
import packageJson from '@/../package.json';

export const namespaced = true;

export const state = {
  projectName: process.env.VUE_APP_PROJECT_NAME || packageJson.name,
  windowWidth: 0,
  shallShowOverlay: false,
  initialMapCoordinates: [55.584227, 37.385534],
  isMobile: false,
  refreshMeta: Math.random(),
};

export const getters = {
  projectName: (state) => state.projectName,
  currentBreakPoint: (state) => {
    const { windowWidth } = state;
    if (windowWidth >= $themeBreakpoints.xl) return 'xl';
    if (windowWidth >= $themeBreakpoints.lg) return 'lg';
    if (windowWidth >= $themeBreakpoints.md) return 'md';
    if (windowWidth >= $themeBreakpoints.sm) return 'sm';
    return 'xs';
  },
  isMobile: (state) => state.isMobile,
};

export const mutations = {
  UPDATE_WINDOW_WIDTH(state, val) {
    state.windowWidth = val;
  },
    UPDATE_REFRESH_META(state) {
    state.refreshMeta = Math.random();
  },
  TOGGLE_OVERLAY(state, val) {
    state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
  },
  SET_IS_MOBILE(state, val) {
    state.isMobile = val;
  }
};

export const actions = {
  async globalSearch({ dispatch }, query) {
    return dispatch('api/get', {
      path: `search/global/?q=${query}`,
    }, { root: true });
  },
  getIsMobile({ commit }) {
    commit('SET_IS_MOBILE', window.innerWidth <= 960);
  },
  async getCoords() {
    let result = [];
    const promise = new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(
            [position.coords.latitude, position.coords.longitude]
        );
      }, () => {
        reject(
            state.initialMapCoordinates
        );
      });
    });

    await promise.then((res) => {
      result = res;
    }).catch((res) => {
      result = res;
    });

    return result;
  }
};
