export const formatDate = (date, {
  withYear = true,
  withDate = true,
  withHours = false,
  withMinutes = false,
  withSeconds = false,
} = {}) => {
  const intl = new Intl.DateTimeFormat('ru', {
    ...(withDate ? (
      withYear ? {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      } : {
        month: 'short',
        day: '2-digit',
      }
    ) : {}),
    ...(withHours ? {
      hour: '2-digit',
      hour12: false,
    } : {}),
    ...(withMinutes ? {
      minute: '2-digit',
    } : {}),
    ...(withSeconds ? {
      second: '2-digit',
    } : {}),
  });
  return intl.format(new Date(date));
};

export const formatDateShort = (date, {
  withYear = true,
  withDate = true,
  withHours = false,
  withMinutes = false,
  withSeconds = false,
} = {}) => {
  const intl = new Intl.DateTimeFormat('ru', {
    ...(withDate ? (
      withYear ? {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      } : {
        month: '2-digit',
        day: '2-digit',
      }
    ) : {}),
    ...(withHours ? {
      hour: '2-digit',
      hour12: false,
    } : {}),
    ...(withMinutes ? {
      minute: '2-digit',
    } : {}),
    ...(withSeconds ? {
      second: '2-digit',
    } : {}),
  });
  // const nDate = typeof date === 'string' ? date.replace(/-/g, '/') : date;
  // console.log(nDate);
  return intl.format(new Date(date));
};

export const getTimeDifference = (timestamp, short = false) => {
  const today = new Date();
  const now = Date.now();

  const todayDate = today.getDate();
  const todayYear = today.getFullYear();

  const date = new Date(timestamp);
  const time = date.getTime();
  const dateDay = date.getDate();
  const dateYear = date.getFullYear();
  const dateTime = formatDate(date, {
    withDate: false,
    withHours: true,
    withMinutes: true,
  });

  const diffMinutes = Math.floor((now - time) / 1000 / 60);

  const isToday = (todayDate === dateDay) && (todayYear === dateYear);
  const isYesterday = ((todayDate - 1) === dateDay) && (todayYear === dateYear);

  if (diffMinutes <= 1) return 'Только что';
  if (diffMinutes < 60) return `${diffMinutes} мин. назад`;
  if (diffMinutes < 120) return `${Math.floor(diffMinutes / 60)} час назад`;
  if (isToday) return `Сегодня ${dateTime}`;
  if (isYesterday) return `Вчера ${dateTime}`;
  return !short ? formatDate(date, {
    withHours: true,
    withMinutes: true,
  }) : formatDateShort(timestamp, {
    withHours: true,
    withMinutes: true,
  });
};

export const getOffsetStatus = (end, offset = 604800000) => {
  const date = new Date(end);

  const curDateTime = Date.now();
  const endDateTime = date.getTime();
  const dateDifference = curDateTime - endDateTime;

  if (endDateTime < curDateTime) return 'expired';
  if (Math.abs(dateDifference) < offset) return 'ending';
  return 'active';
};
