export const namespaced = true;

export const state = {
  gridDisplayed: false,
  types: [],
  statuses: [],
};

export const getters = {

};

export const mutations = {

};

export const actions = {
  getRequestsStatuses({ dispatch }, payload) {
    return dispatch(
      'api/get', {
        path: 'analytics/requests-statuses',
        payload,
      }, { root: true },
    );
  },
  getRequestsEmployees({ dispatch }, payload) {
    return dispatch(
      'api/get', {
        path: 'analytics/requests-employees',
        payload,
      }, { root: true },
    );
  },
  getEmployeesTimeOnline({ dispatch }, payload) {
    return dispatch(
      'api/get', {
        path: 'analytics/employees-time-online',
        payload,
      }, { root: true },
    );
  },
};
