import Vue from 'vue';
import VueRouter from 'vue-router';

import { authMiddleware, guestMiddleware, adminMiddleware } from "@/middlewares/middleware"

import store from '@/store';
import verifyEmail from '@/utils/verifyEmail';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/privacy-policy',
      name: 'privacyPolicy',
      component: () => import('@/views/privacy-policy/index.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'pageTitle.privacyPolicy',
      },
    },
    {
      path: '/sign-in',
      name: 'sign-in',
      component: () => import('@/views/auth/signIn/SignIn.vue'),
      meta: {
        layout: 'full',
      },
      beforeEnter: guestMiddleware,
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      component: () => import('@/views/auth/signUp/SignUp.vue'),
      meta: {
        layout: 'full',
      },
      beforeEnter: guestMiddleware,
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/auth/forgotPassword/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
      beforeEnter: guestMiddleware,
    },
    {
      path: '/',
      name: 'home',
      redirect: '/requests',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        pageTitle: 'pageTitle.home',
      },
      beforeEnter: authMiddleware,
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings/Settings.vue'),
      redirect: { name: 'settings-profile' },
      meta: {
        pageTitle: 'pageTitle.settings',
      },
      beforeEnter: authMiddleware,
      children: [{
        path: 'profile',
        name: 'settings-profile',
        component: () => import('@/views/settings/sections/profile/Profile.vue'),
        meta: {
          pageTitle: 'pageTitle.settings',
          breadcrumb: [
            { text: 'pageTitle.settings', to: { name: 'settings' } },
            { text: 'pageTitle.settings-profile', active: true },
          ],
        },
        beforeEnter: authMiddleware,
      },
      {
        path: 'company',
        name: 'settings-company',
        component: () => import('@/views/settings/sections/company/Company.vue'),
        meta: {
          pageTitle: 'pageTitle.settings',
          breadcrumb: [
            { text: 'pageTitle.settings', to: { name: 'settings' } },
            { text: 'pageTitle.settings-company', active: true },
          ],
        },
        beforeEnter: authMiddleware,
      },
      {
        path: 'users/:id?',
        name: 'settings-employee',
        component: () => import('@/views/settings/sections/employee/Employees.vue'),
        meta: {
          pageTitle: 'pageTitle.settings',
          breadcrumb: [
            { text: 'pageTitle.settings', to: { name: 'settings' } },
            { text: 'pageTitle.settings-employee', active: true },
          ],
        },
        beforeEnter: adminMiddleware,
      },
      {
        path: 'change-password',
        name: 'settings-change-password',
        component: () => import('@/views/settings/sections/changePassword/ChangePassword.vue'),
        meta: {
          pageTitle: 'pageTitle.settings',
          breadcrumb: [
            { text: 'pageTitle.settings', to: { name: 'settings' } },
            { text: 'pageTitle.settings-change-password', active: true },
          ],
        },
        beforeEnter: authMiddleware,
      },
      {
        path: 'file-store',
        name: 'settings-file-store',
        component: () => import('@/views/settings/sections/fileStore/FileStore.vue'),
        meta: {
          pageTitle: 'pageTitle.settings',
          breadcrumb: [
            { text: 'pageTitle.settings', to: { name: 'settings' } },
            { text: 'pageTitle.settings-file-store', active: true },
          ]
        },
        beforeEnter: authMiddleware,
      },
      {
        path: 'notifications',
        name: 'settings-notifications',
        component: () => import('@/views/settings/sections/profile/Profile.vue'),
        meta: {
          pageTitle: 'pageTitle.settings',
          breadcrumb: [
            { text: 'pageTitle.settings', to: { name: 'settings' } },
            { text: 'pageTitle.settings-notifications', active: true },
          ],
        },
        beforeEnter: authMiddleware,
      },
      ],
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        pageTitle: 'pageTitle.tasks',
      },
      beforeEnter: authMiddleware,
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('@/views/clients/list/ClientsList.vue'),
      meta: {
        pageTitle: 'pageTitle.clients',
        breadcrumb: [
          { text: 'pageTitle.clients', to: { name: 'clients' } },
        ],
        permission: 'clients:read',
      },
      beforeEnter: authMiddleware,
    },
    {
      path: '/client/add',
      name: 'client-add',
      component: () => import('@/views/clients/add/ClientAdd.vue'),
      meta: {
        pageTitle: 'pageTitle.client-add',
        breadcrumb: [
          { text: 'pageTitle.clients', to: { name: 'clients' } },
          { text: 'pageTitle.client-add', active: true },
        ],
        permission: 'clients:create',
      },
      beforeEnter: authMiddleware,
      props: true,
    },
    {
      path: '/client/:clientId',
      name: 'client-view',
      component: () => import('@/views/clients/view/ClientView.vue'),
      meta: {
        interactions: {
          entity: 'client',
          idParam: 'clientId',
        },
        pageTitle: 'pageTitle.client-view',
        breadcrumb: [
          { text: 'pageTitle.clients', to: { name: 'clients' } },
          { text: 'pageTitle.client-view', active: true },
        ],
        permission: 'clients:read',
      },
      beforeEnter: authMiddleware,
      props: true,
    },
    {
      path: '/facilities',
      name: 'facilities',
      component: () => import('@/views/facilities/list/FacilitiesList.vue'),
      meta: {
        pageTitle: 'pageTitle.facilities',
        breadcrumb: [
          { text: 'pageTitle.facilities', to: { name: 'facilities' } },
        ],
        permission: 'facilities:read',
      },
      beforeEnter: authMiddleware,
    },
    {
      path: '/facility/add',
      name: 'facility-add',
      component: () => import('@/views/facilities/add/FacilityAdd.vue'),
      meta: {
        pageTitle: 'pageTitle.facility-add',
        breadcrumb: [
          { text: 'pageTitle.facilities', to: { name: 'facilities' } },
          { text: 'pageTitle.facility-add', active: true },
        ],
        permission: 'facilities:create',
      },
      beforeEnter: authMiddleware,
      props: true,
    },
    {
      path: '/facility/:facilityId',
      name: 'facility-view',
      component: () => import('@/views/facilities/view/FacilityView.vue'),
      meta: {
        pageTitle: 'pageTitle.facility-view',
        breadcrumb: [
          { text: 'pageTitle.facilities', to: { name: 'facilities' } },
          { text: 'pageTitle.facility-view', active: true },
        ],
        interactions: {
          entity: 'facility',
          idParam: 'facilityId',
        },
        permission: 'facilities:read',
      },
      beforeEnter: authMiddleware,
      props: true,
    },
    {
      path: '/employees',
      name: 'employees',
      component: () => import('@/views/employees/list/EmployeesList.vue'),
      meta: {
        pageTitle: 'pageTitle.employees',
        breadcrumb: [
          { text: 'pageTitle.employees', to: { name: 'employees' } },
        ],
        permission: 'employees:read',
      },
      beforeEnter: authMiddleware,
    },
    {
      path: '/employee/:employeeId',
      name: 'employee-view',
      component: () => import('@/views/employees/view/EmployeeView.vue'),
      meta: {
        interactions: {
          entity: 'employee',
          idParam: 'employeeId',
        },
        breadcrumb: [
          { text: 'pageTitle.employees', to: { name: 'employees' } },
          { text: 'pageTitle.employee-view', active: true },
        ],
        pageTitle: 'pageTitle.employee-view',
        permission: 'employees:read',
      },
      beforeEnter: authMiddleware,
      props: true,
    },
    {
      path: '/equipment',
      name: 'equipment',
      component: () => import('@/views/equipment/EquipmentIndexPage.vue'),
      meta: {
        pageTitle: 'pageTitle.equipment',
        breadcrumb: [
          { text: 'pageTitle.equipment', to: { name: 'equipment' } },
        ],
        permission: 'equipment:read',
      },
      beforeEnter: authMiddleware,
    },
    {
      path: '/checklists',
      name: 'checklists',
      component: () => import('@/views/checklists/ChecklistsIndexPage.vue'),
      meta: {
        pageTitle: 'pageTitle.checklists',
        breadcrumb: [
          { text: 'pageTitle.checklists', to: { name: 'checklists' } },
        ],
        permission: 'check_lists:read',
      },
      beforeEnter: authMiddleware,
    },
    {
      path: '/checklist/add',
      name: 'checklist-add',
      component: () => import('@/views/checklists/ChecklistAdd.vue'),
      meta: {
        pageTitle: 'pageTitle.checklist-view',
        breadcrumb: [
          { text: 'pageTitle.checklists', to: { name: 'checklists' } },
          { text: 'pageTitle.checklist-view', active: true },
        ],
        permission: 'check_lists:create',
      },
      beforeEnter: authMiddleware,
      props: true,
    },
    {
      path: '/checklist/:checklistId',
      name: 'checklist-view',
      component: () => import('@/views/checklists/ChecklistAdd.vue'),
      meta: {
        pageTitle: 'pageTitle.checklist-view',
        breadcrumb: [
          { text: 'pageTitle.checklists', to: { name: 'checklists' } },
          { text: 'pageTitle.checklist-view', active: true },
        ],
        permission: 'check_lists:read',
      },
      beforeEnter: authMiddleware,
      props: true,
    },
    {
      path: '/scheduler',
      name: 'scheduler',
      component: () => import('@/views/scheduler/SchedulerIndexPage.vue'),
      meta: {
        pageTitle: 'pageTitle.scheduler',
        breadcrumb: [
          { text: 'pageTitle.scheduler', to: { name: 'scheduler' } },
        ],
        permission: 'schedule:read',
      },
      beforeEnter: authMiddleware,
    },
    {
      path: '/scheduler/add',
      name: 'scheduler-add',
      component: () => import('@/views/scheduler/SchedulerAddPage.vue'),
      meta: {
        pageTitle: 'pageTitle.checklist-view',
        breadcrumb: [
          { text: 'pageTitle.scheduler', to: { name: 'scheduler' } },
          { text: 'pageTitle.scheduler-view', active: true },
        ],
        permission: 'schedule:create',
      },
      beforeEnter: authMiddleware,
      props: true,
    },
    {
      path: '/scheduler/:id',
      name: 'scheduler-view',
      component: () => import('@/views/scheduler/SchedulerAddPage.vue'),
      meta: {
        pageTitle: 'pageTitle.scheduler-view',
        breadcrumb: [
          { text: 'pageTitle.scheduler', to: { name: 'scheduler' } },
          { text: 'pageTitle.scheduler-view', active: true },
        ],
        permission: 'schedule:read',
      },
      beforeEnter: authMiddleware,
      props: true,
    },
    {
      path: '/requests',
      name: 'requests',
      component: () => import('@/views/requests/list/RequestsList.vue'),
      meta: {
        pageTitle: 'pageTitle.requests',
        breadcrumb: [
          { text: 'pageTitle.requests', to: { name: 'requests' } },
        ],
        permission: 'requests:read',
      },
      beforeEnter: authMiddleware,
    },
    {
      path: '/requests/add',
      name: 'requests-add',
      component: () => import('@/views/requests/add/RequestsAdd.vue'),
      meta: {
        pageTitle: 'pageTitle.requests-add',
        breadcrumb: [
          { text: 'pageTitle.requests', to: { name: 'requests' } },
          { text: 'pageTitle.requests-add', active: true },
        ],
        permission: 'requests:create',
      },
      beforeEnter: authMiddleware,
      props: true,
    },
    {
      path: '/requests/:id',
      name: 'requests-view',
      component: () => import('@/views/requests/view/RequestsView.vue'),
      meta: {
        pageTitle: 'pageTitle.requests-view',
        interactions: {
          entity: 'request',
          idParam: 'id',
        },
        breadcrumb: [
          { text: 'pageTitle.requests', to: { name: 'requests' } },
          { text: 'pageTitle.requests-view', active: true, entryId: null
          },
        ],
        permission: 'requests:read',
      },
      beforeEnter: authMiddleware,
      props: (route) => ({
        ...route.params
      }),
    },
    {
      path: '/analytics',
      name: 'analytics',
      redirect: '/requests',
      component: () => import('@/views/home/Home.vue'),
      meta: {
        pageTitle: 'pageTitle.analytics',
        breadcrumb: [
          { text: 'pageTitle.analytics', to: { name: 'analytics' } },
        ],
        permission: 'analytics:read',
      },
      beforeEnter: authMiddleware,
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: () => import('@/views/reset-password/index.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'pageTitle.resetPassword',
      },
      beforeEnter: guestMiddleware,
    },
    {
      path: '*',
      name: '404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
});

let firstLoad = true;
router.beforeEach(async (to, from, next) => {
  if (firstLoad) {
    firstLoad = false;

    await store.dispatch('app/getIsMobile');
    await store.dispatch('user/getData');

    const redirect = await verifyEmail(to, store, next);
    if (redirect) return next('/');
  }

  return next();
});

export default router;
