export const namespaced = true;

export const actions = {
  addContactsToClient({ dispatch }, { clientId, contactModel }) {
    return dispatch('api/post', {
      path: `contacts/client/${clientId}`,
      payload: contactModel,
    }, { root: true });
  },
  updateClientContact({ dispatch }, { contactId, contactModel }) {
    return dispatch('api/post', {
      path: `contacts/${contactId}`,
      payload: contactModel,
    }, { root: true });
  },
  async removeClientContact({ dispatch }, { clientId, contactId }) {
    return dispatch('api/delete', {
      path: `contacts/client/${clientId}/${contactId}`,
    }, { root: true });
  },
  addContactsToFacility({ dispatch }, { clientId, contactModel }) {
    return dispatch('api/post', {
      path: `contacts/facility/${clientId}`,
      payload: contactModel,
    }, { root: true });
  },
  updateFacilityContact({ dispatch }, { contactId, contactModel }) {
    return dispatch('api/post', {
      path: `contacts/${contactId}`,
      payload: contactModel,
    }, { root: true });
  },
  async removeFacilityContact({ dispatch }, { clientId, contactId }) {
    return dispatch('api/delete', {
      path: `contacts/facility/${clientId}/${contactId}`,
    }, { root: true });
  },

  async add({ dispatch }, { entityName, entityId, contact }) {
    return dispatch('api/post', {
      path: `contacts/${entityName}/${entityId}`,
      payload: contact,
    }, { root: true });
  },
  async update({ dispatch }, { contact }) {
    return dispatch('api/post', {
      path: `contacts/${contact.id}`,
      payload: contact,
    }, { root: true });
  },
  async remove({ dispatch }, { entityName, entityId, contact }) {
    return dispatch('api/delete', {
      path: `contacts/${entityName}/${entityId}/${contact.id}`,
    }, { root: true });
  },
};
