import countryCodes from '@/store/user/records/countryCodes.json';
import timezones from '@/store/user/records/timezones.json';
import {useStore} from "@/hooks/useStore";

export const namespaced = true;

export const state = {
  countryCodes,
  timezones,
};

export const getters = {
  countryCodes: (state) => state.countryCodes,
  timezones: (state) => state.timezones,
};

export const actions = {
  async verifyEmail({ dispatch }, { email, hash }) {
    return dispatch('api/post', {
      path: `auth/email/verify/${email}/${hash}`,
    }, { root: true });
  },
  async chech({ dispatch }) {
    const { result, status } = await dispatch('api/get', {
      path: '/check',
    }, { root: true });

    if (status === 401) {
      const store = useStore();
      const { result } = await store.dispatch('user/refreshToken');

      return !!result;
    }

    return !!result;
  }
};
