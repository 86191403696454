import router from '@/router'
import store from '@/store';
// import Auth from "@/hooks/Auth";
import Cookies from "js-cookie";

export const authMiddleware = async (from, to, next) => {
    const isLogged = Cookies.get('access-token');
    // if (isLogged) {
    //     isLogged = await Auth(store);
    // }
    next();
    if (!isLogged) {
        if (from.path !== '/') await router.push('/sign-in');
    }
};

export const guestMiddleware = async (from, to, next) => {
    const isLogged = Cookies.get('access-token');
    next();
    if (isLogged) {
        if (from.path !== '/') await router.push('/requests');
    }
};

export const adminMiddleware = async (from, to, next) => {
    await authMiddleware(from, to, next);
    const isAdmin = store.getters['user/roles']('admin');
    next();
    if (!isAdmin) {
        if (from.path !== '/') await router.push('/requests');
    }
};
