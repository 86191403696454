export default async function (route, store) {
  const hash = route?.query?.verifyHash;
  let email = route?.query?.verifyEmail;
  let status = false;
  let redirect = false;

  if (hash && email) {
    // Плюс превращается в пробел из-за vue router
    email = email.split(' ').join('+');

    const { result } = await store.dispatch('auth/verifyEmail', {
      email,
      hash,
    });

    redirect = true;
    status = result;
  }

  if (status) store.commit('confirmEmail', status);
  if (redirect) return true;
  return false;
}
