import { getState, saveState } from './functions';

export const namespaced = true;

export const getters = {
  get: (state) => (prefix, name) => state[`${prefix}_${name}`],
};

export const state = {
  navMenuCollapsed: true,
  firstLoad: true,
};

export const mutations = {
  restoreCacheState(state) {
    Object.assign(state, getState());
  },
  setNavMenuCollapsed(state, value) {
    state.navMenuCollapsed = value;
    saveState('navMenuCollapsed', value);
  },
  setFirstLoad(state, value) {
    state.firstLoad = value;
    saveState('firstLoad', value);
  },

  set(state, { name, value }) {
    saveState(name, value);
    state[name] = value;
  },
};

export const actions = {
  setNavMenuCollapsed({ commit }, value) {
    commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', value, { root: true });
    commit('setNavMenuCollapsed', value);
  },
  setFirstLoad({ commit }, value) {
    commit('setFirstLoad', value);
  },
  restoreCacheState({ commit }) {
    const state = getState();
    commit('restoreCacheState', state);

    if (state.navMenuCollapsed === undefined) {
      commit('setNavMenuCollapsed', true);
      commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true, { root: true });
    }

    if (state.navMenuCollapsed === false) {
      commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false, { root: true });
    }
    if (state.firstLoad === undefined) {
      commit('setFirstLoad', true);
    }
  },
};
