import { loadYmap } from 'vue-yandex-maps';

export const namespaced = true;

const token = process.env.VUE_APP_YANDEX_API_KEY;

const settings = {
    apiKey: token,
    lang: 'ru_RU',
    coordorder: 'latlong',
    enterprise: false,
    version: '2.1'
};

export const actions = {
    async getCities(params, { query }) {
        try {
            let result = [];
            await loadYmap({...settings, debug: true});
            // eslint-disable-next-line no-undef
            await ymaps.geocode(query, {
            }).then((res) => {
                result = res.geoObjects.toArray();
                result = result.map((city) => ({
                        value: city.getAddressLine()
                    }))
            });

            return Promise.resolve(result);
        } catch (err) {
            return Promise.reject(err);
        }
    },

    async getAddresses(params, { query }) {
        try {
            let result = [];
            await loadYmap({...settings, debug: true});
            // eslint-disable-next-line no-undef
            await ymaps.geocode(query, {
                // eslint-disable-next-line consistent-return
            }).then((res) => {
                result = res.geoObjects.toArray();
                if (result.length < 1) {
                    return Promise.resolve([]);
                }
                result = result.map((city) => ({
                    value: city.getAddressLine(),
                    label: city.getAddressLine(),
                    data: {
                        geo_lat: city.geometry.getCoordinates()[0],
                        geo_lon: city.geometry.getCoordinates()[1]
                    }
                }))
            });

            return Promise.resolve(result);
        } catch (err) {
            return Promise.reject(err);
        }
    },

    async geolocate(params, { coords }) {
        try {
            let result = [];
            await loadYmap({...settings, debug: true});
            // eslint-disable-next-line no-undef
            await ymaps.geocode(coords, {
                // eslint-disable-next-line consistent-return
            }).then((res) => {
                result = res.geoObjects.toArray();
                if (result.length < 1) {
                    return Promise.resolve([]);
                }
                result = result.map((city) => ({
                    value: city.getAddressLine(),
                }));
               return result;
            });

            return Promise.resolve(result);
        } catch (err) {
            return Promise.reject(err);
        }
    }
};
