import { axiosIns } from '../api/index';

/**
 * Method to get equipments
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const getEquipments = (params) => axiosIns.get('equipments', { params });

/**
 * Method to get all parent equipments
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const getParentEquipments = (params) => axiosIns.get('equipments/parents', { params });

/**
 * Method to get spareparts
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSpareparts = (params) => axiosIns.get('equipments/spareparts', { params });

/**
 * Method to create equipment
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const createEquipment = (params) => axiosIns.post('equipment', params);

/**
 * Method to update equipment
 * @param { Object } params - request params
 * @param { Number } equipmentId
 * @returns {Promise<AxiosResponse<any>>}
 */
const updateEquipment = (params, equipmentId) => axiosIns.post(`equipment/${equipmentId}`, params);

/**
 * Method to update equipment
 * @param { Number } equipmentId
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteEquipment = (equipmentId) => axiosIns.delete(`equipment/${equipmentId}`);

/**
 * Method to add equipment avatar
 * @param { Number } equipmentId
 * @returns {Promise<AxiosResponse<any>>}
 */
const addEquipmentAvatar = (equipmentId, params) => axiosIns.post(`equipment/${equipmentId}/avatar`, params);

/**
 * Method to delete equipment avatar
 * @param { Number } equipmentId
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteEquipmentAvatar = (equipmentId) => axiosIns.delete(`equipment/${equipmentId}/avatar`);

/**
 * Method to download example excel
 */
const downloadExampleExcel = () => axiosIns.get('equipment/excel/example');

/**
 * Method to import excel
 */
const importExcel = (params) => axiosIns.post('equipment/excel/import', params);

export {
  getEquipments,
  getParentEquipments,
  getSpareparts,
  createEquipment,
  updateEquipment,
  deleteEquipment,
  addEquipmentAvatar,
  deleteEquipmentAvatar,
  downloadExampleExcel,
  importExcel,
};
