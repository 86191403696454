import { axiosIns } from '../api/index';

/**
 * Method to get schedulers
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const getSchedulers = (params) => axiosIns.get('requests-scheduler', { params });

/**
 * Method to get schedulers
 * @param { Number } schedulerId
 * @returns {Promise<AxiosResponse<any>>}
 */
const getScheduler = (schedulerId) => axiosIns.get(`requests-scheduler/${schedulerId}`);

/**
 * Method to get work types
 * @returns {Promise<AxiosResponse<any>>}
 */
const getWorkTypes = () => axiosIns.get('requests/workTypes');

/**
 * Method to get all facilities
 * @returns {Promise<AxiosResponse<any>>}
 */
const getAllFacilities = () => axiosIns.get('facilities/facilitiesForSelector');

/**
 * Method to create scheduler
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const createScheduler = (params) => axiosIns.post('requests-scheduler', params);

/**
 * Method to update scheduler
 * @param { Object } params - request params
 * @param { Number } schedulerId
 * @returns {Promise<AxiosResponse<any>>}
 */
const updateScheduler = (schedulerId, params) => axiosIns.post(`requests-scheduler/${schedulerId}`, params);

/**
 * Method to delete scheduler
 * @param { Object } schedulerId - scheduler id
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteScheduler = (schedulerId) => axiosIns.delete(`requests-scheduler/${schedulerId}`);

export {
  getScheduler,
  getWorkTypes,
  getSchedulers,
  createScheduler,
  updateScheduler,
  deleteScheduler,
  getAllFacilities,
};
