import router from '@/router';

import countryCodes from '@/store/user/records/countryCodes.json';
import timezones from '@/store/user/records/timezones.json';
import currencies from '@/store/user/records/currencies.json';
import countries from '@/store/user/records/countries.json';
import Cookies from 'js-cookie';
import moment from 'moment';

const plans = [
  {
    name: 'basic',
    monthlyPrice: 0,
    popular: false,
  },
  {
    name: 'standard',
    monthlyPrice: 49,
    popular: true,
  },
  {
    name: 'enterprise',
    monthlyPrice: 99,
    popular: false,
  },
];

const SIXTY_MINUTES_IN_MILLISECONDS = 1000 * 60 * 60;
const ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;
const THIRTY_DAYS_IN_MILLISECONDS = ONE_DAY_IN_MILLISECONDS * 30;
// const TWO_YEARS_IN_MILLISECONDS = ONE_DAY_IN_MILLISECONDS * 365 * 2;
const MAXIMUM_TIMER_VALUE = 2147483647;

const accessExpires = new Date(Date.now() + SIXTY_MINUTES_IN_MILLISECONDS);
const refreshExpires = new Date(Date.now() + THIRTY_DAYS_IN_MILLISECONDS);

export const plugins = [
  (store) => {
    store.watch(
      (state) => !!state.user.accessToken.token,
      async (isAuthorized) => {
        const { result, redirect } = await store.dispatch(
          'user/checkAuth',
          { isAuthorized, ...router.currentRoute.meta },
        );

        if (!result) {
          await router.push(redirect);
        }
      },
    );
  },
  (store) => {
    let timeout;
    store.watch(
      (state) => state.user.accessToken.expires,
      (expires) => {
        clearTimeout(timeout);
        if (!expires) return;
        const timeLeft = expires.getTime() - Date.now();
        const tokenAlive = timeLeft > 0;

        if (tokenAlive) {
          if (timeLeft > MAXIMUM_TIMER_VALUE) return;
          timeout = setTimeout(async () => {
            await store.dispatch('user/getData');
          }, timeLeft);
        }
      },
    );
  },
];

export const namespaced = true;

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 100;

export const state = {
  plans,
  timezones,
  currencies,
  countries,
  countryCodes,

  accessToken: {
    token: null,
    expires: null,
  },
  refreshToken: {
    token: null,
    expires: null,
  },
  deviceId: null,
  data: null,
  account: null,
  roles: [],
  passwordMinLength: PASSWORD_MIN_LENGTH,
  passwordMaxLength: PASSWORD_MAX_LENGTH,
};

export const getters = {
  isAuthorized: (state) => !!state.accessToken.token,
  deviceId: (state) => state.data?.deviceId,
  data: (state) => state.data,
  account: (state) => state.account,
  userFullName: (state) => {
    const firstname = state.data?.firstname;
    const surname = state.data?.surname;
    if (!firstname || !surname) return null;

    return `${firstname} ${surname}`;
  },
  avatar: (state) => state.data?.avatar,
  role: (state) => state.data?.role,
  countryCodes: (state) => state.countryCodes,
  timezones: (state) => state.timezones,
  currencies: (state) => state.currencies,
  countries: (state) => state.countries,
  plansList: (state) => state.plans,
  passwordMinLength: (state) => state.passwordMinLength,
  passwordMaxLength: (state) => state.passwordMaxLength,

  permission: (state) => (name) => {
    if (!state?.data?.permissions) return false;
    const allowed = state.data.permissions.find((i) => i.name === `${name}:allowed` || i.name === `${name}:manager`);
    return !!allowed;
  },

  roles: (state) => (name) => {
    if (!state?.data?.role?.name) return false;

    return state.data.role.name === name;
  },
};

export const mutations = {
  SET_ACCESS_TOKEN(state, { token, expires }) {
    state.accessToken = { token, expires };
  },
  SET_REFRESH_TOKEN(state, { token, expires }) {
    state.refreshToken = { token, expires };
  },
  SET_DEVICE_ID(state, { id }) {
    state.deviceId = id
  },
  SET_USER_DATA(state, data) {
    state.data = data;
  },
  SET_ACCOUNT(state, account) {
    state.account = account;
  },
};

export const actions = {
  checkAuth(context, { isAuthorized, guestRequired, authRequired }) {
    if (isAuthorized && guestRequired /* && (getters.tokenValidUntil > new Date()) */) {
      return { result: false, redirect: { name: 'home' } };
    }
    if (!isAuthorized && authRequired) {
      return { result: false, redirect: { name: 'sign-in' } };
    }
    return { result: true };
  },
  async geoLocation({ dispatch }, {deviceId}) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const payload = {
        geo: [{
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
          time: moment().format('YYYY-MM-DD HH:mm:ss'),
          device_id: deviceId,
        }]
      }
      await dispatch('api/post', {
        path: '/user/geo-locations', payload
      }, { root: true });
    })
  },
  async signIn({ dispatch, commit }, { email, password, deviceUid, deviceName, remember }) {
    const payload = { email, password };
    if (deviceUid !== null && deviceName !== null) {
      payload.device_uid = deviceUid;
      payload.device_name = deviceName;
    }
    const { result, data, status } = await dispatch('api/post', {
      path: 'auth/login',
      payload,
    }, { root: true });

    if (result) {
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        userData,
        accounts,
      } = data;

      const refreshExpires = remember
        ? new Date(Date.now() + THIRTY_DAYS_IN_MILLISECONDS)
        : new Date(Date.now() + SIXTY_MINUTES_IN_MILLISECONDS);

      Cookies.set('access-token', accessToken, remember ? { expires: accessExpires } : {});
      Cookies.set('refresh-token', refreshToken, remember ? { expires: refreshExpires } : {});
      Cookies.set('remember', JSON.stringify(remember), { expires: refreshExpires });

      commit('SET_ACCESS_TOKEN', {
        token: accessToken,
        expires: accessExpires,
      });
      commit('SET_REFRESH_TOKEN', {
        token: refreshToken,
        expires: refreshExpires,
      });
      commit('SET_USER_DATA', userData);
      commit('SET_DEVICE_ID', data.device_id);
      commit('SET_ACCOUNT', accounts[0]);
      await router.push('/');
      await dispatch('geoLocation', {deviceId: data.device_id});
      return { result: true, data: userData };
    }

    return { result: false, data, status };
  },
  async signUp({ dispatch, commit }, {
    email, phone, firstname, surname, deviceUid, deviceName,
  }) {
    const payload = { email, phone, firstname, surname };
    if (deviceUid !== null && deviceName !== null) {
      payload.device_uid = deviceUid;
      payload.device_name = deviceName;
    }
    const { result, data, status } = await dispatch('api/post', {
      path: 'auth/register',
      payload,
    }, { root: true });

    if (result) {
      const {
        auth: { access_token: accessToken, refresh_token: refreshToken },
        user: userData,
        account: accounts,
      } = data;

      // if just register - remember user, for easy access
      Cookies.set('access-token', accessToken, { expires: accessExpires });
      Cookies.set('refresh-token', refreshToken, { expires: refreshExpires });

      commit('SET_ACCESS_TOKEN', {
        token: accessToken,
        expires: accessExpires,
      });
      commit('SET_REFRESH_TOKEN', {
        token: refreshToken,
        expires: refreshExpires,
      });
      commit('SET_USER_DATA', userData);
      commit('SET_ACCOUNT', accounts);
      await router.push('/');
      await dispatch('geoLocation', {deviceId: data.device_id});
      return { result: true, data: userData };
    }
    return { result: false, data, status };
  },
  async resetPassword({ dispatch },
    {
      email, password, password2, token,
    }) {
    const { result } = await dispatch('api/post', {
      path: 'auth/reset-password',
      payload: {
        email,
        token,
        password,
        password_confirmation: password2,
      },
    }, { root: true });

    return { result };
  },
  async getData({ dispatch, commit, state }) {
    const token = state.accessToken;
    if (!token?.token) return { result: false };

    const now = new Date();
    const refresh = state.refreshToken;
    if (!refresh?.token) return { result: false };

    if (new Date(refresh.expires) - now < 0) {
      await dispatch('logout');
      return { result: false };
    }

    // eslint-disable-next-line prefer-const
    let { result, data, status } = await dispatch(
        'api/get',
        {
          path: '/check',
        },
        { root: true },
    );
    if (status === 401) {
      const { result: newResult, data: newData, status: newStatus } = await dispatch('refreshToken');
      data = newData;
      result = newResult;
      status = newStatus;
      if (!result) {
        await dispatch('logout');
      }
    }
    if (result) {
      const {
        userData,
        accounts,
      } = data;
      commit('SET_USER_DATA', userData);
      commit('SET_ACCOUNT', accounts[0]);
    } else {
      await dispatch('logout');
    }
    return { result, data, status };
  },
  async refreshToken({ dispatch, commit, state }) {
    const token = state.accessToken;
    if (!token?.token) return { result: false };

    const now = new Date();
    const refresh = state.refreshToken;
    if (!refresh?.token) return { result: false };

    if (new Date(refresh.expires) - now < 0) {
      await dispatch('logout');
      return { result: false };
    }

    const { result, data, status } = await dispatch(
        'api/post',
        {
          path: 'auth/refresh',
          payload: {
            refresh_token: state.refreshToken.token,
          },
        },
        { root: true },
    );
    if (result) {
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        userData,
        accounts,
      } = data;

      Cookies.set('access-token', accessToken, { expires: accessExpires });
      Cookies.set('refresh-token', refreshToken, { expires: refreshExpires });

      commit('SET_ACCESS_TOKEN', {
        token: accessToken,
        expires: accessExpires,
      });
      commit('SET_REFRESH_TOKEN', {
        token: refreshToken,
        expires: refreshExpires,
      });

      commit('SET_USER_DATA', userData);
      commit('SET_ACCOUNT', accounts[0]);
    } else {
      await dispatch('logout');
    }
    return { result, data, status };
  },
  restorePassword({ dispatch }, { email }) {
    return dispatch('api/post', {
      path: 'auth/forgot-password',
      payload: {
        email,
      },
    }, { root: true });
  },
  async logout({ dispatch }) {
    const { result, data, status } = await dispatch('api/get', {
      path: 'auth/logout',
    }, { root: true });
    Cookies.remove('access-token');
    Cookies.remove('refresh-token');
    dispatch('clearUserData');
    return { result, data, status };
  },
  async clearUserData({ commit }) {
    commit('SET_ACCESS_TOKEN', null);
    commit('SET_REFRESH_TOKEN', null);
    commit('SET_USER_DATA', null);
    commit('SET_ACCOUNT', null);
  },
  async updateProfile({ dispatch, commit }, {
    email,
    phone,
    firstname,
    surname,
    is_active: isActive,
    is_serviceman: isServiceman,
  }) {
    const { result, data, status } = await dispatch('api/post', {
      path: 'user',
      payload: {
        email,
        phone,
        firstname,
        surname,
        is_active: isActive,
        is_serviceman: isServiceman,
      },
    }, { root: true });

    if (result) {
      commit('SET_USER_DATA', data);
    }
    return { result, data, status };
  },
  changePassword({ dispatch }, {
    oldPassword,
    newPassword,
  }) {
    return dispatch('api/post', {
      path: 'auth/changePassword',
      payload: {
        oldPassword,
        newPassword,
      },
    }, { root: true });
  },
  async updateCompany({ dispatch, commit }, {
    name,
    country,
    domain,
    email,
    phone,
    currency,
    timezone,
    show_clients: showClients,
  }) {
    const { result, data, status } = await dispatch('api/post', {
      path: 'account',
      payload: {
        name,
        country,
        domain,
        email,
        phone,
        currency,
        timezone,
        show_clients: showClients,
      },
    }, { root: true });

    if (result) {
      commit('SET_ACCOUNT', data);
    }

    return { result, data, status };
  },

  async updateUserAvatar({ dispatch, commit }, { formData }) {
    const { result, data, status } = await dispatch('api/post', {
      path: 'user/avatar',
      payload: formData,
    }, { root: true });

    if (result) {
      commit('SET_USER_DATA', data);
    }
    return { result, data, status };
  },
  async updateCompanyAvatar({ dispatch, commit }, { formData }) {
    const { result, data, status } = await dispatch('api/post', {
      path: 'account/avatar',
      payload: formData,
    }, { root: true });

    if (result) {
      commit('SET_ACCOUNT', data);
    }
    return { result, data, status };
  },

  async deleteUserAvatar({ dispatch, commit }) {
    const { result, data, status } = await dispatch('api/delete', {
      path: 'user/avatar',
    }, { root: true });

    if (result) {
      commit('SET_USER_DATA', data);
    }
    return { result, data, status };
  },
  async deleteCompanyAvatar({ dispatch, commit }) {
    const { result, data, status } = await dispatch('api/delete', {
      path: 'account/avatar',
    }, { root: true });

    if (result) {
      commit('SET_ACCOUNT', data);
    }
    return { result, data, status };
  },

  resendVerification({ dispatch }) {
    return dispatch('api/post', {
      path: 'auth/resendVerificationEmail',
    }, { root: true });
  },

  async getTariffs({ dispatch }) {
    const { result, data, status } = await dispatch('api/get', {
      path: '/file-tariff',
    }, { root: true })

    return { result, data, status }
  },

  async changeTariff({ dispatch }, id) {
    const { result, data, status } = await dispatch('api/post', {
      path: `/file-tariff/${id}`
    }, { root: true });

    return { result, data, status };
  }
};
