export const state = {
    serviceMans: []
};

export const getters = {
    serviceMans: (state) => state.serviceMans
};

export const mutations = {
    SET_SERVICE_MANS(state, data) {
        state.serviceMans = data;
    }
};

export const actions = {
    async getServiceMans({ commit, dispatch }) {
        const { result, data } = await dispatch(
            'api/get', {
                path: `account/employeesForSelector?serviceman=1`,
            }, { root: true },
        );
        if (result) {
            commit('SET_SERVICE_MANS', data);
        }

        return { result, data };
    }
};
