// import { $themeConfig } from '@themeConfig';

export const namespaced = true;

export const state = {
  isVerticalMenuCollapsed: true,
};

export const mutations = {
  UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
    state.isVerticalMenuCollapsed = val;
  },
};
