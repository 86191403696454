import { useStorage } from '@vueuse/core'

const ONE_DAY = 86400000;
const state = useStorage('mobileApp', { show: true, lastUpdate: null }, localStorage);
const setShow = (value) => {
  state.value.show = value;
  state.value.lastUpdate = Date.now();
};
// If the user logs in again and 24 hours have passed
// from the moment the application download box was closed, call him again for now
if (state.value.lastUpdate + ONE_DAY < Date.now()) {
  state.value.show = true;
}
export { setShow, state as mobileAppState };
