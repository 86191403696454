export const namespaced = true;

export const state = {
  visible: false,
  entity: null,
  entityId: null,
  buzy: false,
  list: [],
  unreadCount: 0,
};

export const mutations = {
  open(state) {
    state.visible = true;
    state.unreadCount = 0;
  },
  close(state) {
    state.visible = false;
    state.list = [];
  },
  setEntity(state, { entity, entityId }) {
    state.entity = entity;
    state.entityId = entityId;
  },
  setList(state, list) {
    state.list = list;
    state.buzy = false;
  },
  clear(state) {
    state.visible = false;
    state.entity = null;
    state.entityId = null;
    state.list = [];
    state.buzy = false;
    state.unreadCount = false;
  },
  buzy(state, payload) {
    state.buzy = payload;
  },
  setUnreadCount(state, payload) {
    state.unreadCount = payload;
  },
};

export const actions = {
  async get({ state, commit, dispatch }) {
    commit('buzy', true);

    if (state.entity === 'facility') {
      const { result, data } = await dispatch(
        'api/get', {
          path: `facilities/${state.entityId}/feed`,
        }, { root: true },
      );
      if (result) {
        commit('setList', data);
        return data;
      }
    }

    if (state.entity === 'client') {
      const { result, data } = await dispatch(
        'api/get', {
          path: `clients/${state.entityId}/feed`,
        }, { root: true },
      );
      if (result) {
        commit('setList', data);
        return data;
      }
    }

    if (state.entity === 'request') {
      const { result, data } = await dispatch(
        'api/get', {
          path: `requests/${state.entityId}/feed`,
        }, { root: true },
      );
      if (result) {
        commit('setList', data);
        return data;
      }
    }

    if (state.entity === 'client') {
      const { result, data } = await dispatch(
        'api/get', {
          path: `clients/${state.entityId}/feed`,
        }, { root: true },
      );
      if (result) {
        commit('setList', data);
        return data;
      }
    }

    if (state.entity === 'employee') {
      const { result, data } = await dispatch(
        'api/get', {
          path: `employees/${state.entityId}/feed`,
        }, { root: true },
      );
      if (result) {
        commit('setList', data);
        return data;
      }
    }

    commit('clear');
    return false;
  },
  async open({ commit, dispatch }) {
    commit('open');
    await dispatch('get');
  },
};
