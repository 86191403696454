export function getUserAgent() {
  if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
    return 'android';
  }

  if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
    return 'iphone';
  }
  return 'desktop';
}
