import Vue from 'vue';
import VueMeta from 'vue-meta';
import vClickOutside from 'v-click-outside';
import YmapPlugin from 'vue-yandex-maps';
import vuetify from '@/plugins/vuetify'

import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';

import VueCompositionAPI from '@vue/composition-api';
import { VueMaskDirective } from 'v-mask';
import { Icon } from 'leaflet';
import VueApexCharts from 'vue-apexcharts';
import { i18n } from '@/i18n';
import router from '@/router';
import store from '@/store';
import { formatDate } from '@/utils/time';

import App from '@/App.vue';
import '@/global-components';
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@core/scss/vue/libs/vue-select.scss';

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.use(vClickOutside);

const token = process.env.VUE_APP_YANDEX_API_KEY;

Vue.use(YmapPlugin, {
      apiKey: token,
      lang: 'ru_RU',
      coordorder: 'latlong',
      enterprise: false,
      version: '2.1'
  });

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueCompositionAPI);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.filter('readableDate', formatDate);

Vue.directive('mask', VueMaskDirective);

require('@core/scss/core.scss');
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: "./" })
        .then((reg) => {
            console.log(`Service Worker Registration (Scope: ${reg.scope})`);
        })
        .catch((error) => {
            const msg = `Service Worker Error (${error})`;
            console.error(msg);
        });
} else {
    console.warn('Service Worker not available');
}

const currentBuildId = process.env.VUE_APP_BUILD_ID;
const savedBuildId = localStorage.getItem('buildId');

if (savedBuildId !== currentBuildId) {
  localStorage.clear();
}

localStorage.setItem('buildId', currentBuildId);

new Vue({
    vuetify,
  i18n,
  store,
  router,
  provide: {
    store,
    i18n,
    router,
  },
  render: (h) => h(App),
}).$mount('#app');
