import { axiosIns } from '../api/index';

/**
 * Method to get checklists
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const getChecklists = (params) => axiosIns.get('checklist', { params });

/**
 * Method to get checklists for select
 * @returns {Promise<AxiosResponse<any>>}
 */
const getChecklistsForSelect = () => axiosIns.get('checklist/checklistForSelector');

/**
 * Method to get checklist by id
 * @param { Number } checklistId - request checklist id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getChecklist = (checklistId) => axiosIns.get(`checklist/${checklistId}`);

/**
 * Method to add checklist
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const addChecklist = (params) => axiosIns.post('checklist', params);

/**
 * Method to update checklist
 * @param { Object } checklistId - checklist id
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const updateChecklist = (checklistId, params) => axiosIns.patch(`checklist/${checklistId}`, params);

/**
 * Method to delete checklist
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const checklistDelete = (checklistId) => axiosIns.delete(`checklist/${checklistId}`);

/**
 * Method to add checklist item
 * @param { Number } checklistId - request checklist id
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const addChecklistItem = (checklistId, params) => axiosIns.post(`checklist/${checklistId}/add-item`, params);

/**
 * Method to update checklist item
 * @param { Number } checklistId - request checklist id
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const updateChecklistItem = (checklistId, params) => axiosIns.post(`checklist-item/${checklistId}`, params);

/**
 * Method to duplicate checklist item
 * @param { Number } itemId - request item id
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const duplicateChecklistItem = (itemId, params) => axiosIns.post(`checklist-item/${itemId}/duplicate`, params);

/**
 * Method to delete checklist item
 * @param { Number } itemId - request item id
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteChecklistItem = (itemId) => axiosIns.delete(`checklist-item/${itemId}`);

/**
 * Method to change checklist item position
 * @param { Number } checklistId - request checklist id
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const changeItemPosition = (params) => axiosIns.post('checklist-item/position', params);

/**
 * Method to get checklist tags
 * @returns {Promise<AxiosResponse<any>>}
 */
const getTags = () => axiosIns.get('tags', { params: { item_type: 'checklist' } });

/**
 * Method to add checklist tags
 * @param { Number } checklistId - request checklist id
 * @param { Object } params - request params
 * @returns {Promise<AxiosResponse<any>>}
 */
const addChecklistTag = (checklistId, params) => axiosIns.post(`tags/checklist/${checklistId}`, { tags: [params] });

/**
 * Method to delete checklist item
 * @param { Number } checklistId - request item id
 * @param { Number } tagId - request tag id
 * @returns {Promise<AxiosResponse<any>>}
 */
const deleteChecklistTag = (checklistId, tagId) => axiosIns.delete(`tags/checklist/${`${checklistId}/${tagId}`}`);

/**
 * Method to get checklist equipments
 * @returns {Promise<AxiosResponse<any>>}
 */
const getChecklistEquipments = (checklistId, params) => axiosIns.get(`checklist/${checklistId}/equipments`, params);

/**
 * Method to bind checklist tags
 * @param { Object } params - request params
 * @param { Object } checklistId - checklist id
 * @returns {Promise<AxiosResponse<any>>}
 */
const bindEquipmentToChecklist = (checklistId, params) => axiosIns.post(`checklist/${checklistId}/attach-equipment`, params);

export {
  getChecklistEquipments,
  getChecklistsForSelect,
  bindEquipmentToChecklist,
  updateChecklist,
  getTags,
  addChecklistTag,
  deleteChecklistTag,
  changeItemPosition,
  addChecklistItem,
  updateChecklistItem,
  duplicateChecklistItem,
  deleteChecklistItem,
  getChecklists,
  getChecklist,
  checklistDelete,
  addChecklist,
};
