export const namespaced = true;

const url = process.env.VUE_APP_DA_DATA_API_URL;
const geolocateUrl = process.env.VUE_APP_GEOLOCATE_API_URL;
const token = process.env.VUE_APP_DA_DATA_API_KEY;

export const actions = {
  async getCities(params, { query }) {
    try {
      const body = JSON.stringify({
        from_bound: { value: 'city' },
        to_bound: { value: 'city' },
        locations: [
          {
            city_type_full: 'город',
          },
        ],
        query,
      });
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${token}`,
        },
        body,
      };
      const response = (await (await fetch(url, options)).json()).suggestions;
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async getAddresses(params, { query }) {
    try {
      const body = JSON.stringify({
        from_bound: { value: 'street' },
        to_bound: { value: 'house' },
        query,
      });

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${token}`,
        },
        body,
      };

      const response = (await (await fetch(url, options)).json()).suggestions;
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async geolocate(params, { lat, lon }) {
    try {
      const body = JSON.stringify({
        lat,
        lon,
        count: 1,
      });

      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${token}`,
        },
        body,
      };

      const response = (await (await fetch(geolocateUrl, options)).json()).suggestions;
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  },
};
