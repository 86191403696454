export const namespaced = true;

export const state = {

};

export const getters = {

};

export const mutations = {

};

export const actions = {
  search({ dispatch }, payload) {
    return dispatch(
      'api/get', {
        path: `facilities/${payload.facility_id}/equipments`,
        payload,
      }, { root: true },
    );
  },
};
