import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
export const i18n = new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: loadLocaleMessages(),
});

export const I18nPlugin = {
  install(vue, store) {
    store.$i18n = i18n;
  },
};
