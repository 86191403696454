export const actions = {
    async getNotifications({ dispatch }, payload) {
        const { result, data } = await dispatch(
            'api/get', {
                path: `notifications`,
                payload,
            }, { root: true },
        );

        return { result, data };
    },

    async readAllNotifications({ dispatch }) {
        const { result, data } = await dispatch(
            'api/post', {
                path: `notifications/read-all`,
            }, { root: true },
        );

        return { result, data };
    },

    async readNotification({ dispatch }, { id }) {
        const { result, data } = await dispatch(
            'api/patch', {
                path: `notifications/${id}`,
            }, { root: true },
        );

        return { result, data };
    }
};
