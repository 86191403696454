export const namespaced = true;

export const state = {
  roles: [],
  rolesEntities: [],
};

export const getters = {
  roles: (state) => state.roles,
  rolesEntities: (state) => state.rolesEntities,
};

export const mutations = {
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_ENTITIES(state, entities) {
    state.rolesEntities = entities;
  },
};

export const actions = {
  async getRoles({ dispatch, commit }, payload) {
    const { result, data, status } = await dispatch('api/get', {
      path: 'roles',
      payload,
    }, { root: true });

    if (result) {
      commit('SET_ROLES', data);
    }
    return { result, data, status };
  },
  async getRolesHere({ dispatch }, payload) {
    const { result, data } = await dispatch('api/get', {
      path: 'roles',
      payload,
    }, { root: true });

    if (result) {
      return data;
    }
    return [];
  },
  async getRolesEntities({ dispatch, commit }, payload) {
    const { result, data, status } = await dispatch('api/get', {
      path: 'roles/entities',
      payload,
    }, { root: true });

    if (result) {
      const mappedEntities = Object.entries(data).map((entity) => ({
        name: entity[0],
        label: entity[1].name,
        ...Object.entries(entity[1].actions).reduce((acc, val) => ({
          ...acc,
          [val[0]]: {
            types: val[1].permissionTypes,
          },
        }), {}),
      }));
      commit('SET_ENTITIES', mappedEntities);
    }
    return { result, data, status };
  },
  async updateRolePermission({ getters, dispatch }, {
    roleName,
    permission: {
      entity,
      action,
      permissionType,
    },
  }) {
    const { roles } = getters;
    const currentPermissionRule = roles
      .find((role) => role.name === roleName).permissions
      .find((permission) => permission.name.startsWith(`${entity}:${action}:`));

    const initialPermissionRuleValue = currentPermissionRule.name;
    currentPermissionRule.name = `${entity}:${action}:${permissionType}`;

    const { result, data, status } = await dispatch('api/post', {
      path: `roles/${roleName}`,
      payload: { permission: `${entity}:${action}:${permissionType}` },
    }, { root: true });

    if (!result) {
      currentPermissionRule.name = initialPermissionRuleValue;
    }

    return { result, data, status };
  },
  async updateRole({ dispatch }, { oldName, newName }) {
    return dispatch('api/patch', {
      path: `roles/${oldName}`,
      payload: {
        description: newName,
      },
    }, { root: true });
  },
  createRole({ dispatch }, description) {
    return dispatch('api/post', {
      path: 'roles',
      payload: {
        description,
      },
    }, { root: true });
  },
  deleteRole({ dispatch }, { roleName, newRole }) {
    return dispatch('api/delete', {
      path: `roles/${roleName}`,
      payload: {
        new_role: newRole,
      },
    }, { root: true });
  },

  async getUsers({ dispatch }, payload) {
    const { result, data, status } = await dispatch('api/get', {
      path: 'account/users',
      payload,
    }, { root: true });

    if (result) {
      const {
        data: list,
        current_page: page,
        per_page: perPage,
        total,
      } = data;

      return {
        result,
        status,
        data: {
          list,
          pagination: {
            page,
            perPage,
            total,
          },
        },
      };
    }
    return { result, data, status };
  },
  async getEmployee({ dispatch }, employeeId) {
    return dispatch('api/get', {
      path: `employees/${employeeId}`,
    }, { root: true });
  },
  async getEmployees({ dispatch }, payload) {
    const { result, data, status } = await dispatch('api/get', {
      path: 'employees',
      payload,
    }, { root: true });

    if (result) {
      const {
        data: list,
        current_page: page,
        per_page: perPage,
        total,
      } = data;

      return {
        result,
        status,
        data: {
          list,
          pagination: {
            page,
            perPage,
            total,
          },
        },
      };
    }
    return { result, data, status };
  },

  async getEmployeeRequestsMap({ dispatch }, { employeeId, payload }) {
    const { result, data, status } = await dispatch('api/get', {
      path: `employees/${employeeId}/requests`,
      payload,
    }, { root: true });

    if (result) {
      const {
        data: list,
        current_page: page,
        per_page: perPage,
        total,
      } = data;

      return {
        result,
        status,
        data: {
          list,
          pagination: {
            page,
            perPage,
            total,
          },
        },
      };
    }
    return { result, data, status };
  },

  getEmployeesForSelector({ dispatch }, payload) {
    return dispatch('api/get', {
      path: 'account/employeesForSelector',
      payload,
    }, { root: true });
  },
  async createEmployee({ dispatch }, payload) {
    return dispatch('api/post', {
      path: 'account/users',
      payload,
    }, { root: true });
  },
  async updateEmployee({ dispatch }, { employeeId, model }) {
    return dispatch('api/post', {
      path: `employees/${employeeId}`,
      payload: model,
    }, { root: true });
  },
  // eslint-disable-next-line camelcase
  async deleteEmployee({ dispatch }, { id, manager_id, employee_id }) {
    return dispatch('api/delete', {
      path: `account/users/${id}`,
      payload: {
        manager_id,
        employee_id,
      },
    }, { root: true });
  },

  async updateEmployeeSchedule({ dispatch }, { employeeId, model }) {
    return dispatch('api/post', {
      path: `/schedule-work/employee/${employeeId}`,
      payload: { data: model },
    }, { root: true });
  },
};
