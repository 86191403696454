import { useToast as ut } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export const useToast = () => {
  const toast = ut();

  const welcomeToast = (title, text) => toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      variant: 'success',
      icon: 'CoffeeIcon',
      title,
      text,
    },
  });

  const successToast = (title, text) => toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      variant: 'success',
      icon: 'CheckIcon',
      title,
      text,
    },
  });

  const dangerToast = (title, text, err) => toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      variant: 'danger',
      icon: 'XIcon',
      title,
      text: (err && process.env.NODE_ENV === 'development') ? err : text,
    },
  });

  return {
    welcomeToast,
    successToast,
    dangerToast,
  };
};
