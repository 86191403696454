import Vue from 'vue';
import Vuex from 'vuex';

// Vuexy template modules
import * as app from '@/store/app';
import * as appConfig from '@/store/app-config';
import * as verticalMenu from '@/store/vertical-menu';

// Custom modules
import * as api from '@/store/api';
import * as user from '@/store/user';
import * as auth from '@/store/auth';
import * as employees from '@/store/employees';
import * as clients from '@/store/clients';
import * as facilities from '@/store/facilities';
import * as tags from '@/store/tags';
import * as contacts from '@/store/contacts';
import * as dadata from '@/store/dadata';
import * as yandex from '@/store/yandex';
import * as requests from '@/store/requests';
import * as equipments from '@/store/equipments/equipments';
import * as interactions from '@/store/interactions';
import * as cache from '@/store/cache';
import * as widgets from '@/store/widgets';
import * as mapIcons from '@/store/map';
import * as dictionaries from '@/store/dictionaries';
import * as notifications from '@/store/notifications';

import { arrayFrom } from '@/utils/objectHelpers';

import { getChecklistsForSelect } from '@/store/checklists/checklists.api';
import { getParentEquipments } from '@/store/equipments/equipments.api';
import { getAllFacilities, getWorkTypes } from '@/store/scheduler/scheduler.api';
import { getAllEmployees, getAllManagers } from '@/store/users/users.api';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    api,
    user,
    auth,
    employees,
    clients,
    facilities,
    tags,
    contacts,
    dadata,
    yandex,
    requests,
    equipments,
    interactions,
    cache,
    widgets,
    mapIcons,
    dictionaries,
    notifications,
  },

  state: {
    checklists: [],
    equipments: [],
    workTypes: [],
    employees: [],
    managers: [],
    facilities: [],

    emailVerified: false,
  },

  mutations: {
    confirmEmail(state, payload) {
      state.emailVerified = payload;
    },

    restoreStoreModule(state, {
      module,
      value,
    }) {
      const split = arrayFrom(module)[0].split('.');
      const last = split.pop();

      if (!last) {
        return;
      }

      (split.length
        ? split.reduce((acc, cur) => acc[cur], state)
        : state)[last] = value;
    },

    setChecklists(state, data) {
      state.checklists = data;
    },

    setEquipments(state, data) {
      state.equipments = data;
    },
    setWorkTypes(state, data) {
      state.workTypes = data;
    },
    setManagers(state, data) {
      state.managers = data;
    },
    setFacilities(state, data) {
      state.facilities = data;
    },
    setEmployees(state, data) {
      state.employees = data;
    },
    // setFacilities(state, data) {
    //   state.facilities = data;
    // },

  },

  actions: {
    getChecklists: ({ commit }) => {
      getChecklistsForSelect().then((res) => {
        commit('setChecklists', res.data.response);
      });
    },
    getEquipments: ({ commit }) => {
      getParentEquipments().then((res) => {
        commit('setEquipments', res.data.response.data);
      });
    },
    getWorkTypes: ({ commit }) => {
      getWorkTypes().then((res) => {
        commit('setWorkTypes', res.data.response);
      });
    },
    getManagers: ({ commit }) => {
      getAllManagers().then((res) => {
        commit('setManagers', res.data.response);
      });
    },
    getManagersAsync: ({ dispatch }) => dispatch('api/get', {
      path: 'account/managersForSelector',
    }, { root: true }),

    getEmployees: ({ commit }) => {
      getAllEmployees().then((res) => {
        commit('setEmployees', res.data.response);
      });
    },
    getFacilities: ({ commit }) => {
      getAllFacilities().then((res) => {
        commit('setFacilities', res.data.response);
      });
    },
  },

  getters: {
    allChecklists: (state) => {
      if (state.checklists?.length) {
        return state.checklists.map((item) => ({
          title: item.name.length > 25 ? `${item.name.substring(0, 26)}...` : item.name,
          value: item.id,
        }));
      }
      return [];
    },

    allEquipments: (state) => {
      if (state.equipments?.length) {
        return state.equipments.map((item) => ({
          title: item.title.length > 25 ? `${item.title.substring(0, 26)}...` : item.title,
          value: item.id,
        }));
      }
      return [];
    },

    allEmployees: (state) => {
      if (state.employees?.length) {
        return state.employees.map((item) => ({
          title: `${item.firstname} ${item.surname}`,
          value: item.id,
        }));
      }
      return [];
    },

    allManagers: (state) => {
      if (state.managers?.length) {
        return state.managers.map((item) => ({
          text: `${item.firstname} ${item.surname}`,
          value: item.id,
        }));
      }
      return [];
    },

    allFacilities: (state) => {
      if (state.facilities?.length) {
        return state.facilities.map((item) => ({
          text: item.name.length > 25 ? `${item.name.substring(0, 26)}...` : item.name,
          value: item.id,
        }));
      }
      return [];
    },

    allWorkTypes: (state) => {
      if (state.workTypes?.length) {
        return state.workTypes.map((item) => ({
          text: item.name,
          value: item.id,
        }));
      }
      return [];
    },
  },

  plugins: [
    ...user.plugins,
    (store) => {
      const modules = [
        ['user.accessToken', (val) => (val ? {
          ...val,
          expires: new Date(val.expires),
        } : val)],
        ['user.refreshToken', (val) => (val ? {
          ...val,
          expires: new Date(val.expires),
        } : val)],
        'user.data',
        'user.account',
          'facilities.showServicemans',
      ];

      modules.forEach((module) => {
        const persistedState = JSON.parse(localStorage.getItem('store') || '{}') || {};

        if (!(arrayFrom(module)[0] in persistedState)) {
          return;
        }

        const value = persistedState[arrayFrom(module)[0]];
        store.commit('restoreStoreModule', {
          module,
          value: (arrayFrom(module)[1] || ((s) => s))(value) || value,
        });
      });

      modules.forEach((module) => {
        store.watch((state) => arrayFrom(module)[0].split('.').reduce(
          (acc, cur) => acc?.[cur], state,
        ), (value) => {
          const payload = JSON.parse(localStorage.getItem('store') || '{}') || {};

          payload[arrayFrom(module)[0]] = value;

          localStorage.setItem('store', JSON.stringify(payload));
        }, {
          immediate: true,
          deep: true,
        });
      });
    },
  ],
  strict: process.env.DEV,
});
